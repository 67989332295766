export const Milli = 1
export const Second = 1000
export const Minute = 60 * Second
export const Hour = 60 * Minute
export const Day = 24 * Hour

export const CameraDelay = (idx: number) => (idx + 1) * 100 * Milli

export const Before = (millis: number) => Date.now() - millis

export const Since = (d: Date) => Date.now() - d.getTime()

export const ToShortLocalTime = (v: any) =>
    new Date(v).toLocaleTimeString(undefined, {
        hour: "2-digit",
        minute: "2-digit",
    })

export const ToLongLocalTime = (v: any) =>
    new Date(v).toLocaleTimeString(undefined, {
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
    })

export const ToLocalDate = (v: any) => new Date(v).toLocaleDateString(undefined)

export const ToShortLocalDateTime = (v: any) =>
    new Date(v).toLocaleDateString(undefined, {
        hour: "2-digit",
        minute: "2-digit",
    })

export const ToLongLocalDateTime = (v: any) =>
    new Date(v).toLocaleDateString(undefined, {
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
    })

export const ToDuration = (duration: number) => {
    var sign = duration < 0 ? "-" : ""
    duration = Math.abs(duration)

    const d = Math.floor(duration / Day)
    const h = Math.floor(duration / Hour) % 24
    const m = Math.floor(duration / Minute) % 60
    const s = Math.floor(duration / Second) % 60

    if (d > 0 && h === 0 && m === 0 && s === 0) {
        return `${sign}${d}d`
    }
    if (d > 0) {
        return `${sign}${d}d ${h}:${pad2(m)}:${pad2(s)}`
    }
    return `${sign}${h}:${pad2(m)}:${pad2(s)}`
}

const pad2 = (n: number) => String(n).padStart(2, "0")

export const ToShortFilenameDateTime = (v: any) => {
    const d = new Date(v)
    return `${d.getFullYear()}${pad2(d.getMonth() + 1)}${pad2(d.getDate())}${pad2(d.getHours())}${pad2(d.getMinutes())}`
}

export const ToLongFilenameDateTime = (v: any) => {
    const d = new Date(v)
    return `${d.getFullYear()}-${pad2(d.getMonth() + 1)}-${pad2(d.getDate())} ${pad2(d.getHours())}${pad2(d.getMinutes())}`
}

export const EqualLocalDay = (d1: Date, d2: Date) =>
    d1.getDate() === d2.getDate() && d1.getMonth() === d2.getMonth() && d1.getFullYear() === d2.getFullYear()

export const CropToLocalDate = (d: Date) => new Date(d.getFullYear(), d.getMonth(), d.getDate())

export const CeilToScale = (d: Date, scale: number) => new Date(Math.ceil(d.getTime() / scale) * scale)

export const FloorToScale = (d: Date, scale: number) => new Date(Math.floor(d.getTime() / scale) * scale)

export const AddDuration = (d: Date, duration: number) => new Date(d.getTime() + duration)

export const TimeDifference = (d1: Date, d2: Date) => d2.getTime() - d1.getTime()

export const IsLocalToday = (time: Date) => EqualLocalDay(new Date(), time)

export type TimeRange = {
    start: number
    end: number | null
}

export const IsEmpty = (r: TimeRange) => r.start === r.end
export const IsInside = (smaller: TimeRange, bigger: TimeRange) => {
    if (IsEmpty(smaller)) {
        return true
    }
    if (IsEmpty(bigger)) {
        return false
    }
    if (smaller.start < bigger.start) {
        return false
    }
    if (bigger.end === null) {
        return true
    }
    if (smaller.end === null) {
        return false
    }
    return smaller.end <= bigger.end
}
export const NewRange = (start: number, end: number | null) => {
    return { start, end }
}
export const EmptyRange = () => NewRange(0, 0)
export const OpenFuture = (start: number) => NewRange(start, null)
export const PaddedRange = (mid: number, padding: number) => {
    const end = mid + padding
    return { start: mid - padding, end: end >= Date.now() ? null : end }
}
export const DoOverlap = (a: TimeRange, b: TimeRange) => {
    return !((a.end !== null && a.end < b.start) || (b.end !== null && b.end < a.start))
}
export const MergeRanges = (a: TimeRange, b: TimeRange) => {
    if (IsEmpty(a)) {
        return b
    }
    if (IsEmpty(b)) {
        return a
    }
    return {
        start: Math.min(a.start, b.start),
        end: a.end !== null && b.end !== null ? Math.max(a.end, b.end) : null,
    }
}

export const hookIntervalAndRun = (callback: () => void, ms: number) => {
    callback()
    return hookInterval(callback, ms)
}

export const hookInterval = (callback: () => void, ms: number) => {
    const interval = setInterval(callback, ms)
    return () => clearInterval(interval)
}

export const hookTimeout = (callback: () => void, ms: number) => {
    const timeout = setTimeout(callback, ms)
    return () => clearTimeout(timeout)
}

export const setTimeToEndOfDay = (date: Date): Date => {
    return new Date(date.setHours(23, 59, 59, 999))
}

export const getCurrentTimeAsString = (): string => {
    return new Date().toISOString()
}
